<script>
import "vue-loading-overlay/dist/vue-loading.css";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import HttpRequest from "@/http/HttpRequest";
import Constants from "@/data/Constants";
import Vue from "vue";
import Preference from "@/data/Preference";
import Role from "@/data/Roles";

let roleChecker = new Role();
let preference = new Preference();
let httpRequest = new HttpRequest();

export default {
  data() {
    return {
      title: "Tasks",
      access_property: "Task",
      items: [
        {
          text: "WORKANY",
          to: "/",
        },
        {
          text: "Tasks",
          active: true,
        },
      ],
      role: preference.getData(Preference.KEY_ROLE_ID),
      projectList: [],
      projectTableData: [],
      projectTaskList: [],
      projectMembersList: [],
      projectMembersObjectList: {},
      TaskStatusList: {},
      addProjectMemberID: "",
      taskTableData: [],
      projectType: [],
      totalRowsProject: 1,
      totalRowsProjectTask: 1,
      projectCurrentPage: 1,
      projectPerPage: 10,
      projectPageOptions: [5, 10, 25, 50],
      taskPerPage: 10,
      taskPageOptions: [5, 10, 25, 50],
      taskCurrentPage: 1,
      filterProjects: null,
      filterTaskList: null,
      filterOnProjects: [],
      filterOnTask: [],
      sortBy: "serial_number",
      sortDesc: false,
      editedItem: "",
      projectFields: [
        { key: "serial_number", sortable: true, label: "SN" },
        { key: "project_name", sortable: true, label: "Name" },
      ],
      taskFields: [
        { key: "serial_number", sortable: true, label: "SN" },
        { key: "title", sortable: true, label: "Title", tdClass: "thWidth_title" },
        { key: "task_assignee", sortable: false, label: "Assignee" },
        { key: "task_origin_url", sortable: false, label: "URL" },
        { key: "task_status", sortable: false, label: "Status" },
      ],
      myName: preference.getData(Preference.KEY_NAME),
      myEmail: preference.getData(Preference.KEY_EMAIL),
      myProjectRole: "9",
      selected: {},
      selectedProjectName: "",
      taskTitle: "",
      taskDescription: "",
      taskOriginUrl: "",
      projectMemberId: "",
      selectedProjectID: 1,
      taskOpen: 0,
      taskInProgress: 1,
      taskDone: 2,
      projectMemberMap: {},
      taskStatusMap: {},
      loadingProjects: false,
      loadingTasks: false,
      loadingMembers: false,
    };
  },
  computed: {
    projectRows() {
      return this.projectTableData.length;
    },
    taskRows() {
      return this.taskTableData.length;
    },
  },
  mounted() {
    this.addColumn();
    this.totalRowsProject = this.projectList.length;
    this.totalRowsProjectTask = this.projectTaskList.length;
  },
  components: {
    Layout,
    PageHeader,
  },
  name: "projects",
  watch: {
    filterProjects() {
      this.projectCurrentPage = 1;
    },
    filterTaskList() {
      this.taskCurrentPage = 1;
    },
  },

  methods: {
    isRoleAccess(role_id, resource, action) {
      return roleChecker.checkAccess(role_id.toString(), resource, action);
    },
    doubleRoleCheck(myRole, myRoleOrg, access_property, action) {
      if (
        this.isRoleAccess(myRole, access_property, action) ||
        this.isRoleAccess(myRoleOrg, access_property, action)
      ) {
        return true;
      }
      return false;
    },
    addColumn() {
      if (this.isRoleAccess(this.role, this.access_property, "read")) {
        this.taskFields.push({ key: "action", sortable: false, label: "Action" });
      }
    },
    removeColumn() {
      const tasksList = this.taskFields.findIndex((field) => field.key === "action");
      if (tasksList !== -1) {
        this.taskFields.splice(tasksList, 1);
      }
    },
    getProjectType() {
      let _this = this;

      _this.taskOpen = Constants.TASK_STATUS_OPEN;
      _this.taskInProgress = Constants.TASK_STATUS_IN_PROGRESS;
      _this.taskDone = Constants.TASK_STATUS_DONE;

      httpRequest.getProjectTypes().then(function (response) {
        if (response.success) {
          _this.projectType = response.data;
        }
      });
    },
    getProjectList() {
      let _this = this;
      _this.loadingProjects = true;
      let accessPermission = this.isRoleAccess(this.role, this.access_property, "read");

      return httpRequest.getProjectList(accessPermission).then(function (response) {
        _this.loadingProjects = false;
        if (response.success) {
          _this.projectList = response.data;
          _this.projectTableData.length = 0;

          let tempObj = {};
          let projects_index = 1;
          _this.projectList.forEach(function (projectData) {
            if (projectData.project.project_status == 1) {
              tempObj = {
                serial_number: projects_index,
                project_name: projectData.project.project_name,
                project_id: projectData.project.project_id,
              };
              _this.projectTableData.push(tempObj);
              projects_index = projects_index + 1;
            }
          });

          if (_this.projectTableData.length > 0) {
            _this.selected = _this.projectTableData[0];
            let initialProjID = _this.projectTableData[0].project_id;
            let initialProjName = _this.projectTableData[0].project_name;
            _this.selectedProjectID = initialProjID;
            _this.getProjectTaskList(initialProjID, initialProjName);
            _this.getProjectMembersList(initialProjID);
          }
        }
        return;
      });
    },
    addTaskToProject() {
      let _this = this;
      _this.loadingTasks = true;
      let swal = this.$swal;
      let projMemId = _this.projectMemberMap.get(_this.projectMemberId);
      let accessPermission = this.doubleRoleCheck(
        this.role,
        this.myProjectRole,
        this.access_property,
        "write"
      );
      httpRequest
        .addTask(
          _this.taskTitle,
          _this.taskDescription,
          _this.taskOriginUrl,
          _this.selectedProjectID,
          projMemId,
          accessPermission
        )
        .then(function (response) {
          _this.loadingTasks = false;
          if (response.success) {
            swal(response.message);
            _this.projectTaskList = response.data;
            _this.$bvModal.hide("AddTaskModal");
            _this.getProjectTaskList(_this.selectedProjectID, _this.selectedProjectName);
          } else {
            swal(response.message);
            _this.projectTaskList = [];
          }
        });
    },
    editTaskToProject() {
      let _this = this;
      _this.loadingTasks = true;
      let swal = this.$swal;
      let accessPermission = this.doubleRoleCheck(
        this.role,
        this.myProjectRole,
        this.access_property,
        "update"
      );
      let projMemId = _this.projectMemberMap.get(_this.editedItem.task_assignee);
      let statusChange = _this.taskStatusMap.get(_this.editedItem.task_status_text);
      httpRequest
        .editTask(
          _this.editedItem.title,
          _this.editedItem.task_description,
          _this.editedItem.task_origin_url,
          _this.editedItem.project_id,
          _this.editedItem.task_id,
          projMemId,
          statusChange,
          accessPermission
        )
        .then(function (response) {
          _this.loadingTasks = false;
          if (response.success) {
            _this.projectTaskList = response.data;
            _this.$bvModal.hide("EditTaskModal");
            _this.getProjectTaskList(_this.selectedProjectID, _this.selectedProjectName);
            swal(response.message);
          } else {
            swal(response.message);
            _this.projectTaskList = [];
          }
        });
    },
    getRoleList() {
      let _this = this;
      httpRequest.getRole().then(function (response) {
        if (response.success) {
          _this.projectList = response.data;
        }
      });
    },
    getShortURL(URL) {
      if (URL) {
        return `https://...${URL}`;
      }
      return "";
    },
    getProjectTaskList(project_id, project_name) {
      let _this = this;
      _this.loadingTasks = true;
      httpRequest.getTaskList(project_id).then(function (response) {
        _this.loadingTasks = false;
        _this.selectedProjectName = project_name;
        if (response.success) {
          _this.projectTaskList = response.data;
          _this.taskTableData.length = 0;
          let sn = 0;

          let tempObj = {};
          _this.projectTaskList.forEach(function (taskData, index) {
            let status = taskData.status;
            let statusText = "Open";
            if (status === _this.taskOpen) {
              statusText = "Open";
            } else if (status === _this.taskInProgress) {
              statusText = "In Progress";
            } else if (status === _this.taskDone) {
              statusText = "Done";
            }

            tempObj = {
              serial_number: index + 1,
              task_id: taskData.task_id,
              title: taskData.title,
              task_assignee: taskData.assignee,
              task_origin_url: taskData.task_origin_url,
              task_key: taskData.task_key,
              task_description: taskData.description,
              task_status: status,
              task_status_text: statusText,
              project_id: taskData.project_id,
            };
            let accessPermission = _this.isRoleAccess(
              _this.role,
              _this.access_property,
              "write"
            );

            if (accessPermission) {
              _this.taskTableData.push(tempObj);
            } else if (taskData.assignee == _this.myName) {
              tempObj = {
                serial_number: ++sn,
                task_id: taskData.task_id,
                title: taskData.title,
                task_assignee: taskData.assignee,
                task_origin_url: taskData.task_origin_url,
                task_key: taskData.task_key,
                task_description: taskData.description,
                task_status: status,
                task_status_text: statusText,
                project_id: taskData.project_id,
              };
              _this.taskTableData.push(tempObj);
            }
          });
        } else {
          _this.taskTableData = [];
        }
      });
    },
    getProjectMembersList(project_id) {
      let _this = this;
      _this.loadingMembers = true;
      httpRequest.getProjectMembers(project_id).then(function (response) {
        _this.loadingMembers = false;
        _this.selectedProjectID = project_id;
        if (response.success) {
          _this.projectMembersList = response.data;
          let memberInfoList = [];
          _this.projectMembersList.forEach(function (member) {
            let memberInfo = {};
            memberInfo["name"] = member.user.name;
            memberInfo["email"] = member.user.email;
            memberInfo["project_member_id"] = member.project_member_id;
            memberInfoList.push(memberInfo);
            if (member.user.name == _this.myName && member.user.email == _this.myEmail) {
              _this.myProjectRole = member.role_id;
              if (
                _this.isRoleAccess(
                  _this.myProjectRole.toString(),
                  _this.access_property,
                  "read"
                )
              ) {
                if (
                  _this.isRoleAccess(_this.myProjectRole, _this.access_property, "read")
                ) {
                  _this.taskFields.push({
                    key: "action",
                    sortable: false,
                    label: "Action",
                  });
                }
              } else {
                _this.removeColumn();
              }
            }
            _this.projectMemberMap.set(
              member.user.name,
              parseInt(member.project_member_id)
            );
            Vue.set(
              _this.projectMembersObjectList,
              member.project_member_id,
              member.user.name
            );
          });
          _this.projectMembersList = memberInfoList;
        } else {
          _this.projectMembersList = [];
        }
      });
    },

    onFilteredProject(filteredItems) {
      this.totalRowsProject = filteredItems.length;
      this.currentPage = 1;
    },
    onFilteredTask(filteredItems) {
      this.totalRowsProjectTask = filteredItems.length;
      this.currentPage = 1;
    },
    myRowClickHandler(record) {
      this.selected = record;
      this.selectedProjectID = record.project_id;
      this.getProjectTaskList(record.project_id, record.project_name);
      this.projectMembersObjectList = {};

      this.getProjectMembersList(record.project_id);
    },
    async myEditedItem(row) {
      this.editedItem = row;
      await this.getProjectMembersList(this.editedItem.project_id);
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item == this.selected) {
        return "table-active";
      }
    },
  },
  async created() {
    await this.getProjectList();
    await this.getProjectType();
    this.taskStatusMap = new Map([
      ["Open", 0],
      ["In Progress", 1],
      ["Done", 2],
    ]);

    this.TaskStatusList = ["Open", "In Progress", "Done"];
    this.projectMemberMap = new Map();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <!--Project List block-->
      <div class="col-xl-4 mb-3">
        <div class="card h-100">
          <div class="card-body">
            <h4 class="card-title mb-4">Projects</h4>
            <div class="row mt-4">
              <div class="col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Display
                    <b-form-select
                      v-model="projectPerPage"
                      size="sm"
                      :options="projectPageOptions"
                      :style="{ width: '55px' }"
                    ></b-form-select
                    >&nbsp;
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filterProjects"
                      type="search"
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>

            <!--Project List table-->
            <b-table
              class="mb-0"
              show-empty
              ref="projectTable"
              :items="projectTableData"
              :striped="false"
              :fields="projectFields"
              responsive=""
              :per-page="projectPerPage"
              :current-page="projectCurrentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filterProjects"
              :filter-included-fields="filterOnProjects"
              :bordered="true"
              :hover="true"
              :dark="false"
              :busy="loadingProjects"
              :tbody-tr-class="rowClass"
              @filtered="onFilteredProject"
              @row-clicked="myRowClickHandler"
            >
              <template
                v-for="field in projectFields"
                v-slot:[`head(${field.key})`]="{ label }"
              >
                <span class="" :style="{ color: '#ff7c47' }" v-bind:key="field.key">{{
                  label
                }}</span>
              </template>

              <template slot="actions" slot-scope="projectList">
                <b-btn size="sm" @click="myRowClickHandler(projectList.item)"
                  >Details</b-btn
                >
              </template>

              <template #table-busy>
                <div class="text-center">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
              </template>
            </b-table>
          </div>

          <div class="card-footer bg-white">
            <!--Project List pagination-->
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-md-right">
                  <!-- pagination -->
                  <b-pagination
                    v-model="projectCurrentPage"
                    :limit="3"
                    :total-rows="projectRows"
                    :per-page="projectPerPage"
                    class="pagination-rounded mb-0"
                  ></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--task List block-->
      <div class="col-xl-8 mb-4">
        <div class="card h-100">
          <div class="card-body">
            <h4 class="card-title mb-4">{{ selectedProjectName }} > Tasks</h4>
            <div class="row mt-4">
              <div class="col-md-6">
                <div id="task-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Display
                    <b-form-select
                      v-model="taskPerPage"
                      size="sm"
                      :options="taskPageOptions"
                    ></b-form-select
                    >&nbsp;
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-md-6">
                <div id="task-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filterTaskList"
                      type="search"
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>

            <!--task List table-->
            <b-table
              class="mb-0"
              show-empty
              :striped="false"
              :fields="taskFields"
              :bordered="true"
              :hover="false"
              :dark="false"
              :filter="filterTaskList"
              :filter-included-fields="filterOnTask"
              :busy="loadingTasks"
              :items="taskTableData"
              responsive=""
              :per-page="taskPerPage"
              :current-page="taskCurrentPage"
              @filtered="onFilteredTask"
            >
              <template
                v-for="field in taskFields"
                v-slot:[`head(${field.key})`]="{ label }"
              >
                <span class="" :style="{ color: '#ff7c47' }" v-bind:key="field.key">{{
                  label
                }}</span>
              </template>

              <template v-slot:cell(task_origin_url)="row">
                <b-link
                  v-b-tooltip.hover
                  :title="row.item.task_origin_url"
                  :href="row.item.task_origin_url"
                  target="_blank"
                  >{{ getShortURL(row.item.task_key) }}</b-link
                >
              </template>

              <template v-slot:cell(task_status)="row">
                <span
                  :class="[
                    'badge badge-pill align-self-center mx-auto',
                    row.item.task_status === taskOpen ? 'badge-dark' : 'badge-primary',
                    row.item.task_status === taskInProgress
                      ? 'badge-warning'
                      : 'badge-primary',
                    row.item.task_status === taskDone ? 'badge-success' : 'badge-primary',
                  ]"
                  >{{ row.item.task_status_text }}</span
                >
              </template>

              <template v-slot:cell(action)="row">
                <b-button
                  v-if="doubleRoleCheck(role, myProjectRole, access_property, 'update')"
                  v-b-modal.EditTaskModal
                  variant="outline-info"
                  size="sm"
                  @click="myEditedItem(row.item)"
                  ><i class="ri-pencil-fill"></i
                ></b-button>
              </template>

              <template #table-busy>
                <div class="text-center">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
              </template>
            </b-table>

            <!--task List modals-->
            <div class="align-items-center text-center text-md-left">
              <b-modal id="AddTaskModal" centered title="Add task" hide-footer>
                <form class="form-horizontal" @submit.prevent="">
                  <div class="form-group auth-form-group-custom mb-4">
                    <i
                      class="ri-file-edit-line auti-custom-input-icon customs-text-color"
                    ></i>
                    <label for="taskTitle">Task Title</label>
                    <input
                      type="text"
                      class="form-control"
                      id="taskTitle"
                      placeholder="Enter task title"
                      v-model="taskTitle"
                    />
                  </div>
                  <div class="form-group auth-form-group-custom mb-4">
                    <i
                      class="ri-file-paper-2-line auti-custom-input-icon customs-text-color"
                    ></i>
                    <label for="taskDescription">Task Description</label>
                    <input
                      type="text"
                      class="form-control"
                      id="taskDescription"
                      placeholder="Enter task description"
                      v-model="taskDescription"
                    />
                  </div>
                  <div class="form-group auth-form-group-custom mb-4">
                    <i class="mdi mdi-web auti-custom-input-icon customs-text-color"></i>
                    <label for="orgwebsite">Task origin url</label>
                    <input
                      type="text"
                      class="form-control"
                      id="orgwebsite"
                      placeholder="Enter website link"
                      v-model="taskOriginUrl"
                    />
                  </div>
                  <div class="form-group auth-form-group-custom mb-2">
                    <i
                      class="ri-building-2-line auti-custom-input-icon customs-text-color"
                    ></i>
                    <label for="projectMemberId">Assignee</label>
                    <select
                      class="form-control"
                      id="projectMemberId"
                      v-model="projectMemberId"
                    >
                      <option
                        v-for="(item, key) in projectMembersObjectList"
                        v-bind:key="key"
                      >
                        {{ item }}
                      </option>
                    </select>
                  </div>
                  <div class="mt-4 text-center">
                    <b-button
                      variant="primary"
                      class="customs-bg-color"
                      size="lg"
                      type="submit"
                      v-on:click="addTaskToProject"
                      >SUBMIT
                      <i class="ri-arrow-right-s-line" style="vertical-align: bottom"></i>
                    </b-button>
                  </div>
                </form>
              </b-modal>
              <b-modal id="EditTaskModal" centered title="Edit task" hide-footer>
                <form class="form-horizontal" @submit.prevent="">
                  <div class="form-group auth-form-group-custom mb-4">
                    <i
                      class="ri-file-edit-line auti-custom-input-icon customs-text-color"
                    ></i>
                    <label for="task_title">Task Title</label>
                    <input
                      type="text"
                      class="form-control"
                      id="task_title"
                      placeholder="Enter task title"
                      v-model="editedItem.title"
                    />
                  </div>
                  <div class="form-group auth-form-group-custom mb-4">
                    <i
                      class="ri-file-paper-2-line auti-custom-input-icon customs-text-color"
                    ></i>
                    <label for="task_description">Task Description</label>
                    <input
                      type="text"
                      class="form-control"
                      id="task_description"
                      placeholder="Enter task description"
                      v-model="editedItem.task_description"
                    />
                  </div>
                  <div class="form-group auth-form-group-custom mb-4">
                    <i class="mdi mdi-web auti-custom-input-icon customs-text-color"></i>
                    <label for="org_website">Task origin url</label>
                    <input
                      type="text"
                      class="form-control"
                      id="org_website"
                      placeholder="Enter website link"
                      v-model="editedItem.task_origin_url"
                    />
                  </div>
                  <div class="form-group auth-form-group-custom mb-2">
                    <i
                      class="ri-building-2-line auti-custom-input-icon customs-text-color"
                    ></i>
                    <label for="org-mem-list">Assignee</label>
                    <select
                      class="form-control"
                      id="org-mem-list"
                      v-model="editedItem.task_assignee"
                    >
                      <option
                        v-for="(item, key) in projectMembersObjectList"
                        v-bind:key="key"
                      >
                        {{ item }}
                      </option>
                    </select>
                  </div>

                  <div class="form-group auth-form-group-custom mt-4">
                    <i
                      class="ri-check-line auti-custom-input-icon customs-text-color"
                    ></i>
                    <label for="org-mem-list-">Task Status</label>
                    <select
                      class="form-control"
                      id="org-mem-list-"
                      v-model="editedItem.task_status_text"
                    >
                      <option v-for="(item, key) in TaskStatusList" v-bind:key="key">
                        {{ item }}
                      </option>
                    </select>
                  </div>

                  <div class="mt-4 text-center">
                    <b-button
                      variant="primary"
                      class="customs-bg-color"
                      size="lg"
                      type="submit"
                      v-on:click="editTaskToProject"
                      >UPDATE
                      <i class="ri-arrow-right-s-line" style="vertical-align: bottom"></i>
                    </b-button>
                  </div>
                </form>
              </b-modal>
            </div>
          </div>

          <div class="card-footer bg-white">
            <!--task List btn & pagination-->
            <div class="row">
              <div class="col-md-auto mb-2 mb-md-0 text-center text-md-left">
                <b-button
                  v-b-modal.AddTaskModal
                  variant="primary"
                  v-if="
                    this.doubleRoleCheck(
                      this.role,
                      myProjectRole,
                      this.access_property,
                      'write'
                    )
                  "
                  class="customs-bg-color"
                  ><i class="ri-add-line" style="vertical-align: bottom"></i>ADD
                  TASK</b-button
                >
              </div>
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-md-right">
                  <!-- pagination -->
                  <b-pagination
                    v-model="taskCurrentPage"
                    :limit="3"
                    :total-rows="taskRows"
                    :per-page="taskPerPage"
                    class="pagination-rounded mb-0"
                  ></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style>
.thWidth_title {
  width: 300px;
}

.customs-bg-color {
  background-color: #ff7c47 !important;
  border: none;
}
.customs-text-color {
  color: #ff7c47 !important;
}
</style>
>
